import axios from ".";

// 
export const register = (params = {}) => {
    return axios.post('/user', params)
}

export const login = (params = {}) => {
    return axios.post('/user/token', params)
}

export const alterPwd = (params = {}) => {
    return axios.put('/user/token', params)
}

export const getVerifyCode = (params = {}) => {
    return axios.post('/user/verifyCode', params)
}

export const getUserInfo = () => {
    return axios.get('/user')
}


export const getResetPwdVerifyCode = (params = {phone_number: null}) => {
    return axios.post('/user/password', params)
}

export const resetPassword = (params = {phone_number, verify_code, new_password}) => {
    return axios.put('/user/password', params)
}
