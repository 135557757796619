<script setup>
import { ElInput, ElButton, ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import {
  register,
  getVerifyCode,
  getResetPwdVerifyCode,
  resetPassword,
} from "@/api/user.js";
import { debounce } from "@/src/utils/utils.js";
import { useRouter } from "vue-router";
const props = defineProps({
  isLogin: {
    type: Boolean,
    default: true,
  },
});
// const resetForm = reactive({
//   username: "kzq777",
//   password: "777",
//   confirmPwd: "777",
//   verifyCode: "1449902124",
// });
const resetForm = reactive({
  phone_number: "",
  new_password: "",
  confirmPwd: "",
  verify_code: "",
});
// let email = ref("test@qq.com");
const checkForm = reactive({
  new_password: "",
  confirmPwd: "",
  phone: "",
  verify_code: "",
});
const _timeout = 60;

let verifyCodeTimeout = ref(_timeout);
let disabledSend = ref(false);

let loading = ref(false);
const router = useRouter();

const checkIsEmpty = (formItem, content) => {
  console.log(resetForm[formItem]);
  if (!resetForm[formItem]) {
    checkForm[formItem] = `${content}不能为空`;
    return false;
  }
  if (formItem === "username" && resetForm[formItem].length < 6) {
    checkForm[formItem] = `${content}的长度不能少于6`;
    return false;
  }
  checkForm[formItem] = "";
  return true;
};
const checkConfirmPwd = () => {
  if (resetForm["confirmPwd"] != resetForm["new_password"]) {
    checkForm["confirmPwd"] = `两次密码不一致`;
    return false;
  }

  checkForm["confirmPwd"] = "";
  return true;
};

const checkPhoneNumber = () => {
  // const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  const reg = /^1[3456789]\d{9}$/;
  console.log(resetForm.phone_number);
  if (!resetForm.phone_number) {
    checkForm["phone"] = "手机号码不能为空";
    return false;
  }
  if (!reg.test(resetForm.phone_number)) {
    checkForm["phone"] = "手机号码格式不正确";
    return false;
  }
  checkForm["phone"] = "";
  return true;
};

const debounceCheckIsEmpty = debounce(900, checkIsEmpty);
const debouncecheckConfirmPwd = debounce(900, checkConfirmPwd);
const debouncecheckPhoneNumber = debounce(1200, checkPhoneNumber);

const getCode = () => {
  if (disabledSend.value) {
    return;
  }
  if (!checkPhoneNumber()) {
    return;
  }
  getResetPwdVerifyCode({ phone_number: resetForm.phone_number }).then(
    (res) => {
      ElMessage({
        type: "success",
        message: "发送成功",
      });
    }
  );
  let interval = setInterval(() => {
    verifyCodeTimeout.value = verifyCodeTimeout.value - 1;

    if (verifyCodeTimeout.value <= 0) {
      clearInterval(interval);
      verifyCodeTimeout.value = _timeout;
      disabledSend.value = false;
    }
  }, 1000);
  disabledSend.value = true;
};
const resetPwdFn = () => {
  loading.value = true;
  resetPassword({
    phone_number: resetForm.phone_number,
    verify_code: resetForm.verify_code,
    new_password: resetForm.new_password,
  })
    .then((res) => {
      loading.value = false;
      const {
        data: { message },
      } = res;
      if (message === "success") {
        ElMessage({
          type: "success",
          message: "重置成功",
        });
        router.push("/login");
      }
    })
    .catch(() => {
      loading.value = false;
    });
};

// support using enter key to login in
const enterResetPwdFn = () => {
  resetPwdFn();
};
</script>

<template>
  <div class="login">
    <div class="wrap">
      <h2 class="loginTitle">忘记密码</h2>
      <div class="formItem">
        <div class="label">
          <span class="formName">手机号码</span>
          <span class="checkInfo">{{ checkForm.phone }}</span>
        </div>

        <el-input
          v-model="resetForm.phone_number"
          class="formItem verifyEmail"
          @blur="debouncecheckPhoneNumber"
          @input="debouncecheckPhoneNumber"
        >
          <template #append>
            <span
              class="getVerifyCodeBt"
              @click="getCode"
              :class="{ isVaild: !disabledSend }"
              >{{
                disabledSend ? verifyCodeTimeout + "后重试" : "获取验证码"
              }}</span
            >
          </template>
        </el-input>
      </div>
      <div class="formItem">
        <div class="label">
          <span class="formName">验证码</span>
          <span class="checkInfo">{{ checkForm.verify_code }}</span>
        </div>
        <el-input
          v-model="resetForm.verify_code"
          class="formItem verifyEmail"
          @blur="debounceCheckIsEmpty('verify_code', '验证码')"
          @input="debounceCheckIsEmpty('verify_code', '验证码')"
        >
        </el-input>
      </div>
      <div class="formItem">
        <div class="label">
          <span class="formName">新密码</span>
          <span class="checkInfo">{{ checkForm.new_password }}</span>
        </div>
        <el-input
          v-model="resetForm.new_password"
          @keyup.enter="enterLogin()"
          class="formItem"
          type="password"
          show-password
          @blur="debounceCheckIsEmpty('new_password', '新密码')"
          @input="debounceCheckIsEmpty('new_password', '新密码')"
        >
        </el-input>
      </div>
      <div>
        <div class="formItem">
          <div class="label">
            <span class="formName">确认密码</span>
            <span class="checkInfo">{{ checkForm.confirmPwd }}</span>
          </div>
          <el-input
            v-model="resetForm.confirmPwd"
            class="formItem"
            type="password"
            show-password
            @blur="debouncecheckConfirmPwd"
            @input="debouncecheckConfirmPwd"
            @keyup.enter="enterResetPwdFn"
          >
          </el-input>
        </div>
      </div>

      <div class="btlist">
        <el-button
          class="loginBt bt"
          type="primary"
          @click="resetPwdFn"
          :loading="loading"
          >重置</el-button
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap {
  box-sizing: border-box;
  width: 500px;
  border: 1px solid #ddd;
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 30px;
}

.loginTitle {
  color: #409eff;
  margin-bottom: 20px;
  text-align: center;
}

.formItem {
  margin-bottom: 45px;
  height: 35px;
}
.label {
  margin-bottom: 5px;
}

.warnText {
  height: 30px;
}

.btlist {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.btlist .bt {
  width: 100%;
  height: 40px;
  font-size: 16px;
}

.getVerifyCodeBt {
  cursor: pointer;
  width: 65px;
}

:deep(.verifyEmail .el-input-group__append),
:deep(.verifyEmail .el-input-group__prepend) {
  background-color: transparent;
}
.isVaild {
  color: #409eff;
}

.checkInfo {
  float: right;
  color: #f56c6c;
}

.forgetPwd {
  cursor: pointer;
}

.forgetPwd:hover {
  color: #409eff;
}
</style>

