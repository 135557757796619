import axios from ".";

export const uploadFileTask = (params = {}, onUploadProgress) => {
    return axios.post('/file', params, { onUploadProgress })
}

export const getFileInfo = (params = { file_id }) => {
    return axios.get(`/file/${params.file_id}`)
}


export const getTaskList = (params = { page: 1, limit: 5 }) => {
    return axios.get('/task', { params: params })
}

export const createTask = (params = {}) => {
    return axios.post('/task', params)
}

export const startTask = (params = {}) => {
    return axios.put(`/task/${params.task_id}`)
}

export const getTaskDetail = (params = {}) => {
    return axios.get(`/task/${params.task_id}`)
}

export const pollTask = () => {
    return axios.get('/task/0')
}