import { getChargePrice, getChargeUrl, checkCharge } from "../../api/charge"
import { ElMessage } from "element-plus"
export const chargeStore = {
    namespaced: true,
    state: {
        visible: false,

        // 充值列表
        priceList: [],

        // 自定义充值列表
        customPriceList: [],

        // 当前选中的价格Index
        activateIndex: 0,

        curPrice: null,

        // 轮询订单计时器
        pollingInterval: null,

        // 订单信息
        chargeInfo: {
            order_url: '',
            trade_no: '',
            total_amount: ''
        },

        iframeLoading: false,

        firstOpen: true
    },
    getters: {

    },
    mutations: {
        toggleVisible(state) {
            state.visible = !state.visible
        },

        setPollingInterval(state, interval) {
            state.pollingInterval = interval
        },

        clearPollingInterval(state) {
            clearInterval(state.pollingInterval)
            state.pollingInterval = null
        },

        setPriceList(state, priceList) {
            state.priceList = priceList
        },

        setCustomPriceList(state, priceList) {
            state.customPriceList = priceList
        },

        setActiveIndex(state, index) {
            state.activateIndex = index
            state.curPrice = state.priceList[index]?.price || state.customPriceList[0].price
        },

        setChargeInfo(state, chargeInfo) {
            state.chargeInfo = chargeInfo
        },

        openIframeLoading(state) {
            state.iframeLoading = true
        },

        closeIframeLoading(state) {
            state.iframeLoading = false
        },

        clearCustomPriceList(state) {
            state.customPriceList = []
        },
        changeFirstOpen(state, status){
            console.log(status)
            state.firstOpen = status
        }
    },
    actions: {
        getPriceList({ commit, state, dispatch }, params = {}) {
            const isCustomSearch = Object.keys(params).length !== 0
            if (isCustomSearch && !params?.['recharge_count_seq']) {
                commit('clearCustomPriceList')
                return;
            }
            // 
            if(state.customPriceList.length && state.firstOpen){
                console.log(state.firstOpen)
                dispatch('activateCharge', state.priceList.length)
                commit('changeFirstOpen', false)
                return;
            }
            getChargePrice(params).then(res => {
                const { data: {
                    data
                } } = res
                if (isCustomSearch) {
                    commit('setCustomPriceList', data)
                    dispatch('activateCharge', state.priceList.length)
                } else {
                    commit('setPriceList', data)
                    dispatch('activateCharge', 0)
                    commit('changeFirstOpen', false)
                }
            })
        },

        closeChargeDialog({ commit }) {
            commit('clearPollingInterval')
            commit('toggleVisible');
            commit('changeFirstOpen', true)

            // commit('clearCustomPriceList');
        },

        async activateCharge({ state, commit, dispatch }, index) {
            commit('setActiveIndex', index)
            commit('openIframeLoading')
            commit('clearPollingInterval')
            const recharge_count = state.priceList?.[index]?.recharge_count || state.customPriceList?.[0].recharge_count;
            getChargeUrl({ recharge_count,qr_pay_mode:2 }).then((res) => {
                const {
                    data: {
                        data: { order_url, trade_no, total_amount },
                    },
                } = res;
                commit('setChargeInfo', {
                    order_url,
                    trade_no, total_amount
                })
                dispatch('pollingCharge')
            })

        },

        pollingCharge({ state, commit, dispatch }) {
            const interval = setInterval(() => {
                checkCharge({ page: 1, limit: 1, trade_no: state.chargeInfo.trade_no }).then(
                    (res) => {
                        const {
                            data: {
                                data: { pay_status },
                            },
                        } = res;
                        if (pay_status == 2) {
                            ElMessage({
                                type: "success",
                                message: "支付成功",
                            });
                            dispatch('closeChargeDialog')
                            dispatch('updateUserInfo', null, { root: true })
                        }
                    }
                );
            }, 2000);
            commit('setPollingInterval', interval)
        }

    }
}