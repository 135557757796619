<template>
  <div class="task" disabled="isLogin">
    <div class="btList">
      <el-upload ref="uploadRef" :accept="acceptFileType.join(',')" :disabled="!isLogin" v-model:file-list="fileList"
        :before-upload="beforeUploadFn" :on-change="handleChangeFile" :on-remove="clearUploadFile" @click="checkIsLogin"
        :http-request="uploadFile">
        <el-button type="primary" :loading="isUploading" class="uploadBt">上传文件</el-button>
      </el-upload>
      <el-button type="primary" class="uploadBt" @click="createTaskFnThrottle" :disabled="!isUploadFinish">
        开始分析
      </el-button>
    </div>
    <div class="progress" v-if="fileList.length">
      <el-progress :percentage="curUploadProgress"></el-progress>
    </div>
    <el-table :data="tableData.data.map(tableDataMap).filter(onlyShowStatusNotZeroTask)" stripe class="taskTable"
      v-loading="dataLoading" max-height="500" border>
      <el-table-column prop="file_name" label="文件名" align="center" show-overflow-tooltip />
      <el-table-column prop="status" label="任务状态" align="center">
        <template #default="scope">
          <span :class="{
            runingText: scope.row.status === 1,
            finishText: scope.row.status === 2,
            failedText: scope.row.status === 3,
          }">{{ statu2Text[scope.row.status] || noData }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="start_time" label="开始时间" align="center" />
      <el-table-column prop="end_time" label="结束时间" align="center" />
      <el-table-column prop="cost" label="花费篇数" align="center" />
      <el-table-column prop="result" label="任务结果" align="center">
        <template #default="scope">
          <a :href="getDownLoadUrl(scope.row.result, scope.row.file_name)" target="_blank" v-if="scope.row.result">
            <Download class="downloadIcon" />
          </a>
          <span v-else>{{ noData }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination v-model:current-page="page" background layout="prev, pager, next" :page-size="pageSize"
        :total="totalCt" @current-change="getTableData" hide-on-single-page :pager-count="5" />
    </div>
  </div>
  <ChargeInfoDialog v-if="chargeInfoDialogVisible" :visible="chargeInfoDialogVisible" :costInfo="costInfoList"
    @closeChargeDialog="closeChargeDialog" @createTaskFn="createTaskFn" @openChargePage="openChargePage">
  </ChargeInfoDialog>
</template>



<script setup>
import {
  createTask, getFileInfo, getTaskList, pollTask, startTask, uploadFileTask
} from "@/api/task.js";
import { Download } from "@element-plus/icons-vue";
import {
  ElButton,
  ElMessage, ElMessageBox, ElPagination, ElProgress, ElTable,
  ElTableColumn, ElUpload
} from "element-plus";
import { useStore } from "vuex";

import { computed, reactive, ref } from "vue";
import { acceptFileType, downLoadURL, imgPath } from "../../../../constant";
import { throttle } from "../../../utils/utils";
import ChargeInfoDialog from "../../Dialog/chargeInfoDialog.vue";

const isLogin = ref(!!localStorage.getItem("username"));
const uploadRef = ref();
const fileList = ref([]);
let curFileId = ref();
let isUploading = ref(false);
let dataLoading = ref(false);
let timeout = null;
let interval = null;
let tableData = reactive({
  data: [],
});
const isUploadFinish = ref(false)

let curUploadProgress = ref(0);

const store = useStore();
const costInfoList = reactive({});
const chargeInfoDialogVisible = ref(false);
const onlyShowStatusNotZeroTask = (task) => {
  return task?.status !== 0;
};
const noData = "/";
const statu2Text = {
  0: "未开始",
  1: "运行中",
  2: "已完成",
  3: "失败",
};
const pollTimeout = 3000;
let hasRuningTask = computed({
  get: () => {
    return tableData.data.some((task) => task.status === 1);
  },
});
const checkIsLogin = () => {
  if (!isLogin.value) {
    ElMessage({
      type: "warning",
      message: "请先登录",
    });
    return false;
  }
  return true;
};
const handleChangeFile = (file, fileList) => {
  if (fileList.length > 1) {
    fileList.splice(0, 1);
  }
};

// 查看文件信息
const getFileInfoById = (file_id) => {
  getFileInfo({ file_id: file_id }).then((res) => {
    const {
      data: {
        data: { cost },
      },
    } = res;
    costInfoList.value = cost;
    curFileId.value = file_id;
  });
};

const onUploadProgress = (e) => {
  const { progress } = e;
  curUploadProgress.value = Math.floor(progress * 100);
};
const beforeUploadFn = () => {
  curUploadProgress.value = 0;
};
// 上传文件
const uploadFile = (params) => {
  const formData = new FormData();
  formData.append("file", params.file);
  isUploading.value = true
  uploadFileTask(formData, onUploadProgress).then(
    (res) => {
      isUploading.value = false;
      isUploadFinish.value = true;
      if (res) {
        const {
          data: {
            data: { file_id },
          },
        } = res;
        ElMessage({
          type: "success",
          message: "上传成功",
        });
        getFileInfoById(file_id);
      }
    },
    (rej) => {
      const {
        response: {
          data: { message },
        },
      } = rej;
      isUploading.value = false;
      isUploadFinish.value = false;
      ElMessage({
        type: "error",
        message: message,
        onClose: clearUploadFile,
      });
    }
  );
};

const tableDataMap = (data) => {
  const { start_time, end_time, task_id, status } = data;
  const new_task_id = task_id ?? noData;
  const new_start_time = start_time
    ? new Date(start_time * 1000).toLocaleString()
    : noData;
  const new_end_time = end_time
    ? new Date(end_time * 1000).toLocaleString()
    : noData;
  return {
    ...data,
    start_time: new_start_time,
    end_time: new_end_time,
    task_id: new_task_id,
  };
};
const updateTaskList = () => {
  const fetchapi = () => {
    if (!localStorage.getItem("username")) {
      clearInterval(interval);
      interval = null;
      return;
    }
    if (!interval) {
      interval = setInterval(fetchapi, pollTimeout);
    }
    if (!hasRuningTask.value) {
      return;
    }
    pollTask().then((res) => {
      const {
        data: {
          data: { task_detail, file_detail },
        },
      } = res;
      if (!res) {
        clearInterval(interval);
        interval = null;
        return;
      }
      let runingTask = tableData.data.findIndex(
        (task) => task.task_id === task_detail.task_id
      );
      if (tableData.data[runingTask].status !== task_detail.status) {
        tableData.data[runingTask] = {
          ...task_detail,
          file_name: file_detail.raw_filename,
        };
        store.dispatch("updateUserInfo");
        clearInterval(interval);
        interval = null;
      }
    });
  };
  fetchapi();
};
const startTaskFn = (data) => {
  startTask({ task_id: data.task_id }).then((res) => {
    if (res) {
      tableData.data.unshift({ ...data, status: 1 });
      if (!timeout) {
        timeout = setTimeout(() => {
          updateTaskList();
          clearTimeout(timeout);
          timeout = null;
        }, pollTimeout);
      }
    }
    ElMessage({
      type: "success",
      message: "正在分析，请稍后",
    });
    clearUploadFile();
    store.dispatch("updateUserInfo");
  });
};
const clearUploadFile = () => {
  curFileId.value = null;
  fileList.value.length = 0;
  curUploadProgress.value = 0;
  isUploadFinish.value = false;
};

const checkUseOneTime = () => {
  chargeInfoDialogVisible.value = true;
};

const checkBeforeCreateTask = () => {
  if (!checkIsLogin()) {
    return;
  }
  if (!curFileId.value) {
    ElMessage({
      type: "error",
      message: "请先上传文件",
    });
    return;
  }
  checkUseOneTime();
};
const closeChargeDialog = () => {
  chargeInfoDialogVisible.value = false;
};

const createTaskFn = () => {
  closeChargeDialog();
  createTask({ file_id: curFileId.value })
    .then((res) => {
      if (res) {
        const {
          data: { data: task_detail },
        } = res;
        startTaskFn({ ...task_detail, file_name: fileList.value?.[0].name });
      }
    })
    .catch((rej) => {
      const {
        data: { code, message },
        hideErrorMsg: hideErrorMsg,
      } = rej;
      if (message === "次数已用尽") {
        hideErrorMsg();
        showTipMessageBox();
      }
      console.log(message);
    });
};

const showTipMessageBox = () => {
  const msgBox = `
    <div>使用次数用尽，请添加站长微信获取更多使用次数</div>
    <img src="${imgPath}" style="width:150px; margin-top:20px"></img>
  `;
  ElMessageBox.alert(msgBox, "提示", {
    dangerouslyUseHTMLString: true,
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
  });
};

const createTaskFnThrottle = throttle(checkBeforeCreateTask, 1200);

// 分页相关
const page = ref(1);
const pageSize = ref(5);
const totalCt = ref(0);
const getTableData = () => {
  dataLoading.value = true;
  getTaskList({
    page: page.value,
    limit: pageSize.value,
  })
    .then((res) => {
      const {
        data: {
          data: { task_list, total },
        },
      } = res;
      totalCt.value = total;
      tableData.data = task_list.map((task) => {
        return {
          ...task.task_detail,
          file_name: task.file_detail.raw_filename,
        };
      });
      dataLoading.value = false;
    })
    .catch(() => {
      dataLoading.value = false;
    });
};
const getDownLoadUrl = (path, file_name) => {
  return `${downLoadURL}${path}?jwt=${localStorage.getItem(
    "token"
  )}&attname=${file_name}.xlsx`;
};
if (isLogin.value) {
  dataLoading.value = true;
  getTaskList({
    page: page.value,
    limit: pageSize.value,
  })
    .then((res) => {
      const {
        data: {
          data: { task_list, total },
        },
      } = res;
      totalCt.value = total;
      tableData.data = task_list.map((task) => {
        return {
          ...task.task_detail,
          file_name: task.file_detail.raw_filename,
        };
      });
      dataLoading.value = false;
      timeout = setTimeout(() => {
        updateTaskList();
        clearTimeout(timeout);
        timeout = null;
      }, pollTimeout);
    })
    .catch(() => {
      dataLoading.value = false;
    });
}

const openChargePage = () => {
  closeChargeDialog();
  store.commit("chargeStore/toggleVisible");
};
</script>



<style scoped>
.task {
  flex: 1;
  position: relative;
}

.btList {
  display: flex;
  padding: 30px 30px 15px 30px;
  height: 60px;
}

.uploadBt {
  width: 100px;
  margin-right: 40px;
}

.taskTable {
  width: 90%;
}

.runingText {
  color: #409eff;
}

.finishText {
  color: #67c23a;
}

.failedText {
  color: #f56c6c;
}

.is-disabled {
  color: #ddd !important;
}

.downloadIcon {
  width: 20px;
  color: #409eff;
  cursor: pointer;
}

.pagination {
  width: 90%;
  display: flex;
  justify-content: right;
  margin-top: 30px;
}

.progress .el-progress--line {
  width: 330px;
  margin: 0 0 15px 30px;
}
</style>
