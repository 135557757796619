import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import { defineAsyncComponent } from 'vue'
import home from '@/src/views/home/home.vue'
import login from '@/src/views/login/login.vue'
import main from '@/src/views/main/main.vue'
import readme from '@/src/views/main/readme/readme.vue'
import resetPwd from '@/src/views/login/resetPwd.vue'


const router = createRouter({
    // history: createWebHashHistory(),  // hash 模式
    history: createWebHashHistory(),  // history 模式
    routes: [
        {
            path: '/',
            name: 'home',
            component: home,
            redirect: '/main',
            children: [
                {
                    path: '/main',
                    name: 'main',
                    component: main
                },
                {
                    path: '/login',
                    name: 'login',
                    props: {
                        isLogin: true

                    },
                    component: login
                },
                {
                    path: '/resetPwd',
                    name: 'resetPwd',
                    component: resetPwd,
                },
                {
                    path: '/register',
                    name: 'register',
                    props: {
                        isLogin: false
                    },
                    component: login,
                },

                {
                    path: '/readme',
                    name: 'readme',
                    component: readme
                }
            ]
        },


    ]
})

// 全局路由守卫
router.beforeEach((to, from, next) => {
    // console.log(to, from)
    // if (to.meta.title) {
    //     document.title = `${to.meta.title}`;
    // }
    if (to.path) {
        if (window._hmt) {
            window._hmt.push(['_trackPageview', '/#' + to.fullPath])
        }
    }
    next()
})

router.afterEach((to, from) => {
    // console.log(to, from)
    console.log('afterEach')
})

export default router