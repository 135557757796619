<script setup>
import { ref } from "vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { debounce } from "../../utils/utils";
import { imgPath } from "../../../constant";
const { useState, useActions, useMutations } =
  createNamespacedHelpers("chargeStore"); // specific module name
const {
  visible,
  priceList,
  activateIndex,
  customPriceList,
  chargeInfo,
  iframeLoading,
} = useState([
  "visible",
  "priceList",
  "activateIndex",
  "customPriceList",
  "chargeInfo",
  "iframeLoading",
]);
const { closeChargeDialog, getPriceList, activateCharge } = useActions([
  "closeChargeDialog",
  "getPriceList",
  "activateCharge",
]);
const { setActiveIndex, closeIframeLoading, clearCustomPriceList } =
  useMutations([
    "setActiveIndex",
    "closeIframeLoading",
    "clearCustomPriceList",
  ]);

const props = defineProps({
  name: {
    type: String,
    default: "-",
  },
  count: {
    type: Number,
    default: 0,
  },
});

// data
const inputRef = ref();
const customChargeCount = ref(customPriceList?.value?.[0]?.recharge_count);
const searchPriceList = () => {
  if (!customChargeCount.value) {
    customChargeCount.value = "";
    clearCustomPriceList();
    return;
  }
  customChargeCount.value = parseInt(customChargeCount.value);
  if (customChargeCount.value <= 0) {
    customChargeCount.value = 1;
  }
  curServiceCount.value = customChargeCount.value;
  getPriceList({ recharge_count_seq: customChargeCount.value });
};
const debounceGetPrice = debounce(1000, searchPriceList);

const dealPrice = (p) => {
  if (!p) {
    return;
  }
  if (/\./.test(p)) {
    return p.toFixed(2);
  }
  return p;
};

const activateCustomItem = () => {
  inputRef.value.focus();
};

const curServiceCount = ref(50);
const activateFn = (index) => {
  curServiceCount.value = priceList.value[index].recharge_count;
  activateCharge(index);
};

const activateCustomFn = () => {
  activateCustomItem();
  debounceGetPrice();
};
getPriceList();

const goToPayPage = (url) => {
  window.open(url);
};
</script>
<template>
  <div>
    <el-dialog
      :model-value="visible"
      :show-close="true"
      @close="closeChargeDialog"
      class="chargeDialog"
    >
      <template #header>
        <div class="my-header">
          <div class="headerItem">
            <span>充值账号：</span>
            <span class="usernameText">{{ name }}</span>
          </div>
        </div>
        <div class="headerItem">
          <span>剩余篇数：</span>
          <span :class="[count == 0 ? 'redText' : 'greenText']">{{
            count
          }}</span>
        </div>
      </template>
      <!-- <div class="wechat">
        <img :src="imgPath" alt="" style="width: 300px" />
        <h2>请添加微信进行充值</h2>
      </div> -->
      <div class="pricePage">
        <div
          :class="['chargeItem', activateIndex === index ? 'activateItem' : '']"
          v-for="(item, index) in priceList"
          :key="index + item?.price"
          @click="activateFn(index)"
        >
          <div class="itemTitle">{{ item.recharge_count }}篇</div>
          <div class="price">
            ¥
            <span class="priceText">{{ item.price }}</span>
          </div>
          <div class="originPrice" v-if="item.origin_price !== item.price">
            ¥
            <span class="originPriceText">{{ item.origin_price }}</span>
          </div>
        </div>
        <div
          :class="[
            'chargeItem',
            activateIndex == priceList.length ? 'activateItem' : '',
          ]"
          @click="activateCustomFn"
        >
          <div>
            <input
              class="customInput itemTitle"
              placeholder="自定义"
              @input="debounceGetPrice"
              v-model="customChargeCount"
              ref="inputRef"
            />
            <span class="itemTitle">篇</span>
          </div>
          <div v-if="customPriceList.length">
            <div class="price">
              ¥
              <span class="priceText">{{
                dealPrice(customPriceList?.[0].price)
              }}</span>
            </div>
            <div
              class="originPrice"
              v-if="
                customPriceList?.[0].origin_price > customPriceList?.[0].price
              "
            >
              ¥
              <span class="originPriceText">{{
                dealPrice(customPriceList?.[0].origin_price)
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="chargePage">
        <div class="priceInfo">
          <div>
            <span>商品信息：</span>
            <span class="bolderText">文本分析服务</span>
          </div>
          <div>
            <span>商品个数：</span>
            <span class="bolderText">{{ curServiceCount }}</span>
          </div>
          <div>
            <span>商品单价：</span>
            <span class="bolderText">¥1</span>
          </div>
          <div>
            <span>商品总价：</span>
            <span class="bolderText totalPrice"
              >¥{{ chargeInfo?.total_amount }}</span
            >
          </div>
          <div class="btList">
            <el-button
              type="primary"
              @click="goToPayPage(chargeInfo?.order_url)"
            >
              支付宝支付
              <!-- <a :href="chargeInfo?.order_url" target="_blank">支付宝支付</a> -->
            </el-button>
          </div>
          <div class="tips">（若充值失败，请添加首页微信进行充值）</div>
          <!-- <div class="wechat">
            <img :src="imgPath" alt="" style="width: 300px" />
            <h2>请添加微信进行充值</h2>
          </div> -->
        </div>
        <!-- <div class="iframeWrap" v-loading="iframeLoading">
          <iframe
            :src="chargeInfo?.order_url"
            frameborder="0"
            scrolling="no"
            v-if="chargeInfo?.order_url"
            @load="closeIframeLoading"
          ></iframe>
        </div> -->
        <!-- <div class="priceInfo">
          请使用支付宝支付
          <span class="infoText">{{ chargeInfo?.total_amount }}</span>
          元
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>


<style scoped>
.my-header {
  width: 100%;
}

.usernameText {
  color: #409eff;
  font-weight: bold;
  margin-right: 20px;
}

:deep(.el-dialog) {
  width: 60%;
  max-width: 800px;
  min-width: 600px;
  border-radius: 5px;
}
:deep(.el-dialog__header) {
  margin: 0;
  padding: 20px;
  border-bottom: 1px solid #ddd;
}
.redText {
  color: #f56c6c;
  font-weight: bold;
}
.greenText {
  font-weight: bold;
  color: #67c23a;
}

.headerItem {
  font-size: 18px;
  margin-top: 10px;
}

.pricePage {
  display: flex;
  justify-content: space-around;
  height: 230px;
}
.chargeItem {
  width: 180px;
  height: 200px;
  border-radius: 10px;
  margin: 10px 30px 30px 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border: 1px solid transparent;
}

.chargeItem:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.itemTitle {
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
}
/* @keyframes moveTop {
  0% {
    top: 50px;
  }
  100% {
    top: 0;
  }
} */
.customInput {
  border: none;
  width: 50%;
  padding-bottom: 10px;
  text-align: center;
  position: relative;
  border-bottom: 1px solid #ebc96a;
}
.customInput::placeholder {
  color: #ddd;
}
.customInput:focus {
  outline: none;
}
.price {
  color: #dfb349;
  font-size: 16px;
  margin: 40px 0;
}

.priceText {
  font-size: 35px;
  font-weight: bold;
}
.originPrice {
  text-decoration: line-through;
}

.activateItem {
  border: 1px solid #ebc96a;
}

.chargePage {
  width: 100%;
  height: 350px;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 10px;
  box-sizing: border-box;
}

.chargePage .iframeWrap {
  width: 100%;
  height: 90%;
  overflow: hidden;
}
.chargePage iframe {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.priceInfo {
  text-align: left;
  font-size: 18px;
}
.priceInfo > div {
  margin-top: 25px;
}
.infoText {
  font-weight: bolder;
}
.wechat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chargePage {
  display: flex;
  justify-content: center;
}
.bolderText {
  font-weight: bold;
}

.btList {
  text-align: center;
  font-size: large;
}

.btList .el-button {
  padding: 10px;
  font-size: 18px;
  height: 40px;
}

.totalPrice {
  font-size: 20px;
  color: #dfb349;
}

.tips{
  font-size: 16px;
}
</style>