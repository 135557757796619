<script setup>
import Info from "./info/info.vue";
import Task from "./task/task.vue";
import {
  ArrowLeftBold,
  ArrowRightBold,
  ArrowDownBold,
  ArrowUpBold,
} from "@element-plus/icons-vue";
import { ref } from "vue";
import { getSideBarState, setSideBarState } from "../../utils/utils";

let isClose = ref(getSideBarState());

const changeSideBar = () => {
  setSideBarState(!isClose.value);
  isClose.value = !isClose.value;
};

setTimeout(() => {
  if (!isClose.value && document.body.clientWidth <= 700) {
    isClose.value = true;
  }
}, 3000);
</script>


<template>
  <div class="topbar" @click="changeSideBar">
    <ArrowUpBold v-if="!isClose" class="arrowIcon" />
    <ArrowDownBold v-else class="arrowIcon" />
  </div>
  <div class="leftContent" :class="{ isClose: isClose }">
    <Info />
  </div>
  <div class="sidebar">
    <div class="sidebar-icon" @click="changeSideBar">
      <ArrowLeftBold v-if="!isClose" class="arrowIcon" />
      <ArrowRightBold v-else class="arrowIcon" />
    </div>
  </div>

  <div class="rightContent">
    <Task />
  </div>
</template>


<style scoped>
.leftContent {
  flex: 0 0 25%;
}
.rightContent {
  width: 100%;
  padding: 15px 0 0 60px;
}
.sidebar {
  width: 15px;
  display: flex;
  margin-top: 300px;
  justify-content: center;
  cursor: pointer;
}

.sidebar .sidebar-icon {
  width: 15px;
  height: 80px;
  background: #ddd;
  color: #999;
  opacity: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
.sidebar-icon:hover {
  color: #000;
}

.isClose {
  display: none;
}
</style>