import { createStore } from 'vuex'
import { getUserInfo } from '@/api/user';
import { chargeStore } from './charge.store'
import { clearUserInfo } from './../utils/utils';
export default createStore({
    modules: {
        chargeStore
    },
    state: { remainCount: '', isLogin: false },
    actions: {
        async updateUserInfo({ commit, state }) {
            getUserInfo().then(res => {
                const {
                    data: {
                        data: { balance },
                    },
                } = res;
                state.remainCount = balance
                commit('setLoginStatus', true)
            })
        },
        logOut({ commit }) {
            clearUserInfo()
            commit('setLoginStatus', false)
        },
        login({ commit }) {
            commit('setLoginStatus', true)
        }
    },
    mutations: {
        openChargeDialog(state) {
            console.log(111111)
            state.chargePageVisible = true
        },
        closeChargeDialog(state) {
            state.chargePageVisible = false
        },
        setLoginStatus(state, status) {
            state.isLogin = status
        }
    }
})
