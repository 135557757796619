<script setup>
import { getVerifyCode, login, register } from "@/api/user.js";
import { debounce } from "@/src/utils/utils.js";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const props = defineProps({
  isLogin: {
    type: Boolean,
    default: true,
  },
});
// const loginForm = reactive({
//   username: "kzq777",
//   password: "777",
//   confirmPwd: "777",
//   verifyCode: "1449902124",
// });
const loginForm = reactive({
  username: "",
  password: "",
  confirmPwd: "",
  verifyCode: "",
});
// let phone = ref("test@qq.com");
let phone = ref("");
const checkForm = reactive({
  username: "",
  password: "",
  confirmPwd: "",
  phone: "",
  verifyCode: "",
});
const _timeout = 60;

const visible = ref(false);
let verifyCodeTimeout = ref(_timeout);
let disabledSend = ref(false);

let loading = ref(false);
const router = useRouter();

const checkIsEmpty = (formItem, content) => {
  if (!loginForm[formItem]) {
    checkForm[formItem] = `${content}不能为空`;
    return false;
  }
  if (formItem === "username" && loginForm[formItem].length < 6) {
    checkForm[formItem] = `${content}的长度不能少于6`;
    return false;
  }
  checkForm[formItem] = "";
  return true;
};
const checkConfirmPwd = () => {
  if (loginForm["confirmPwd"] != loginForm["password"]) {
    checkForm["confirmPwd"] = `两次密码不一致`;
    return false;
  }

  checkForm["confirmPwd"] = "";
  return true;
};

const checkphone = () => {
  // const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  const reg = /^1[3456789]\d{9}$/;
  if (!phone.value) {
    checkForm["phone"] = "手机号码不能为空";
    return false;
  }
  if (!reg.test(phone.value)) {
    checkForm["phone"] = "手机号码格式不正确";
    return false;
  }

  checkForm["phone"] = "";
  return true;
};

const checkRegister = () => {
  return (
    checkIsEmpty("username", "用户名") &&
    checkIsEmpty("password", "密码") &&
    checkConfirmPwd() &&
    checkIsEmpty("verifyCode", "验证码")
  );
};

const checkLogin = () => {
  return checkIsEmpty("username", "用户名") && checkIsEmpty("password", "密码");
};
const debounceCheckIsEmpty = debounce(900, checkIsEmpty);
const debouncecheckConfirmPwd = debounce(900, checkConfirmPwd);
const debouncecheckphone = debounce(900, checkphone);

const getCode = () => {
  if (disabledSend.value) {
    return;
  }
  if (!checkphone()) {
    return;
  }
  getVerifyCode({ phone_number: phone.value }).then((res) => {
    ElMessage({
      type: "success",
      message: "发送成功",
    });
  });
  let interval = setInterval(() => {
    verifyCodeTimeout.value = verifyCodeTimeout.value - 1;

    if (verifyCodeTimeout.value <= 0) {
      clearInterval(interval);
      verifyCodeTimeout.value = _timeout;
      disabledSend.value = false;
    }
  }, 1000);
  disabledSend.value = true;
};
const loginFn = () => {
  // if (!checkLogin()) {
  //   return;
  // }
  loading.value = true;
  login({
    username: loginForm.username,
    password: loginForm.password,
  })
    .then((res) => {
      loading.value = false;
      if (res) {
        const {
          data: {
            data: { token },
          },
        } = res;
        ElMessage({
          type: "success",
          message: `欢迎你， ${loginForm.username}`,
        });
        localStorage.setItem("token", token);
        localStorage.setItem("username", loginForm.username);
        router.push("/");
        store.dispatch("login");
      }
    })
    .catch(() => {
      loading.value = false;
    });
};

const registerFn = () => {
  if (!checkRegister()) {
    return;
  }
  loading.value = true;
  register({
    username: loginForm.username,
    password: loginForm.password,
    phone_number: phone.value,
    verify_code: loginForm.verifyCode,
  })
    .then((res) => {
      loading.value = false;
      if (res) {
        ElMessage({
          type: "success",
          message: "注册成功",
        });
        router.push("/login");
      }
    })
    .catch(() => {
      loading.value = false;
    });
};

// support using enter key to login in
const enterLogin = () => {
  if (props.isLogin) {
    loginFn();
  }
};

const router2ResetPage = () => {
  router.push("/resetPwd");
};

store.dispatch("logOut");
</script>

<template>
  <div class="login">
    <div class="wrap">
      <h2 class="loginTitle">{{ isLogin ? "登录" : "注册" }}</h2>
      <div class="formItem">
        <div class="label">
          <span class="formName" v-if="!isLogin">用户名</span>
          <span class="formName" v-else>用户名/手机号码</span>

          <span class="checkInfo">{{ checkForm.username }}</span>
        </div>
        <el-input
          v-model="loginForm.username"
          class="formItem"
          type="text"
          minlength="6"
          maxlength="16"
          show-word-limit
          @blur="debounceCheckIsEmpty('username', '用户名')"
          @input="debounceCheckIsEmpty('username', '用户名')"
        ></el-input>
      </div>
      <div class="formItem">
        <div class="label">
          <span class="formName">密码</span>
          <span class="checkInfo">{{ checkForm.password }}</span>
        </div>
        <el-input
          v-model="loginForm.password"
          @keyup.enter="enterLogin()"
          class="formItem"
          type="password"
          show-password
          @blur="debounceCheckIsEmpty('password', '密码')"
          @input="debounceCheckIsEmpty('password', '密码')"
        >
        </el-input>
      </div>
      <div v-if="!isLogin">
        <div class="formItem">
          <div class="label">
            <span class="formName">确认密码</span>
            <span class="checkInfo">{{ checkForm.confirmPwd }}</span>
          </div>
          <el-input
            v-model="loginForm.confirmPwd"
            class="formItem"
            type="password"
            show-password
            @blur="debouncecheckConfirmPwd"
            @input="debouncecheckConfirmPwd"
          >
          </el-input>
        </div>
        <div class="formItem">
          <div class="label">
            <span class="formName">验证手机</span>
            <span class="checkInfo">{{ checkForm.phone }}</span>
          </div>
          <el-input
            v-model="phone"
            class="formItem verifyphone"
            @blur="debouncecheckphone"
            @input="debouncecheckphone"
          >
            <template #append>
              <span
                class="getVerifyCodeBt"
                @click="getCode"
                :class="{ isVaild: !disabledSend }"
                >{{
                  disabledSend ? verifyCodeTimeout + "后重试" : "获取验证码"
                }}</span
              >
            </template>
          </el-input>
        </div>
        <div class="formItem">
          <div class="label">
            <span class="formName">验证码</span>
            <span class="checkInfo">{{ checkForm.verifyCode }}</span>
          </div>
          <el-input
            v-model="loginForm.verifyCode"
            class="formItem verifyphone"
            @blur="debounceCheckIsEmpty('verifyCode', '验证码')"
            @input="debounceCheckIsEmpty('verifyCode', '验证码')"
          >
          </el-input>
        </div>
      </div>
      <div v-if="isLogin" class="forgetPwd" @click="router2ResetPage">
        忘记密码
      </div>

      <div class="btlist">
        <el-button
          class="loginBt bt"
          type="primary"
          @click="loginFn"
          v-if="isLogin"
          :loading="loading"
          >登录</el-button
        >
        <el-button
          class="registerBt bt"
          v-else
          type="primary"
          :loading="loading"
          @click="registerFn"
          >注册</el-button
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap {
  box-sizing: border-box;
  width: 500px;
  border: 1px solid #ddd;
  border-radius: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 30px;
}

.loginTitle {
  color: #409eff;
  margin-bottom: 20px;
  text-align: center;
}

.formItem {
  margin-bottom: 45px;
  height: 35px;
}
.label {
  margin-bottom: 5px;
}

.warnText {
  height: 30px;
}

.btlist {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.btlist .bt {
  width: 100%;
  height: 40px;
  font-size: 16px;
}

.getVerifyCodeBt {
  cursor: pointer;
  width: 65px;
}

:deep(.verifyphone .el-input-group__append),
:deep(.verifyphone .el-input-group__prepend) {
  background-color: transparent;
}
.isVaild {
  color: #409eff;
}

.checkInfo {
  float: right;
  color: #f56c6c;
}

.forgetPwd {
  cursor: pointer;
}

.forgetPwd:hover {
  color: #409eff;
}
</style>

