import axios from ".";


export const getChargeUrl = (params = { "recharge_count": 0 }) => {
    return axios.post('/pay/recharge', params)
}


export const checkCharge = (params = { page: 1, limit: 1, trade_no: null }) => {
    return axios.get('/pay/recharge', {params: params})
}


export const getChargePrice = (params = {}) => {
    return axios.get('/pay/price', {params: params})
}