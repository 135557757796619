<script setup>
import { nextTick, ref } from "vue-demi";
import { readmeHtmlPath } from "../../../../constant";

let iframeLoading = ref(true);
const iframeRef = ref();
nextTick(() => {
  iframeRef.value.onload = () => {
    iframeLoading.value = false
  };
});
</script>
<template>
  <div v-loading="iframeLoading" class="iframeWrap">
    <iframe
      :src="readmeHtmlPath"
      frameborder="0"
      class="readmeIframe"
      ref="iframeRef"
    ></iframe>
  </div>
</template>

<style scoped>
.iframeWrap {
  width: 100%;
  height: 100%;
  padding: 30px 0 30px 45px;
  box-sizing: border-box;
}
.readmeIframe {
  width: 100%;
  height: 100%;
}
</style>