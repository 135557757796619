import store from '@/src/store/index.js';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import axios from '../api';
import App from './App.vue';
import router from './router';
import './style.less';

const VueApp = createApp(App)

VueApp.config.globalProperties.axios = axios

VueApp.use(router)
VueApp.use(store)
VueApp.use(ElementPlus)
VueApp.use(VueGtag, {
    config: { id: "G-1EKV1BF4SR" }
}, router);
VueApp.mount('#app')


