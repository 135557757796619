<script setup>
import {
  ElButton,
  ElDialog,
  ElLoadingDirective,
  ElMessage,
  ElTable,
  ElTableColumn,
} from "element-plus";
import { computed, onMounted, ref, watch } from "vue";
import { getChargeUrl, checkCharge } from "@/api/charge.js";
import { useStore } from "vuex";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  costInfo: {
    type: Object,
    default: {},
  },
});
const emit = defineEmits("closeChargeDialog");
const curCost = props.costInfo.value?.cost ?? 0;
const store = useStore();
const count = store.state.remainCount;
const costInfo = {
  1000: 1,
  5000: 2,
  10000: 3,
};
const keyList = Object.keys(costInfo)
  .map((e) => parseInt(e))
  .sort((a, b) => a - b);
const costTableData = [];

console.log(keyList);
keyList.forEach((e, index) => {
  const curData = {
    ct: costInfo[e],
  };
  if (index === 0) {
    curData["info"] = `<${e}`;
  } else if (index > 0 && index < keyList.length - 1) {
    curData["info"] = `${keyList[index - 1]}-${e}`;
  } else {
    curData["info"] = `>${e}`;
  }
  costTableData.push(curData);
});

const noRemain = computed(()=>{
  return count < curCost
})
</script>
<template>
  <div>
    <el-dialog
      :model-value="visible"
      :show-close="true"
      @close="$emit('closeChargeDialog')"
      class="chargeInfoDialog"
    >
      <template #header>
        <h3 class="title">提示</h3>
      </template>

      <div class="costInfo">
        <div class="infoItem">
          当前文件消耗篇数：
          <span class="costText">{{ curCost }}</span>
          次
        </div>
        <div class="infoItem">
          剩余篇数：<span :class="[count == 0 ? 'redText' : 'greenText']">{{
            count
          }}</span
          >篇
        </div>
        <div class="remainInfo infoItem" v-if="noRemain">
          剩余篇数不足，请充值！
        </div>

        <el-table stripe class="costInfoTable" border :data="costTableData">
          <el-table-column
            label="文本词数"
            align="center"
            prop="info"
          ></el-table-column>
          <el-table-column
            label="消耗篇数"
            align="center"
            prop="ct"
          ></el-table-column>
        </el-table>
        <div class="tips">（任务若分析失败，篇数将会返回账户中）</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="$emit('closeChargeDialog')">取消</el-button>
          <el-button type="primary" @click="$emit('createTaskFn')">
            确认
          </el-button>
          <el-button v-if="noRemain" type="success" @click="$emit('openChargePage')">
            充值
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>


<style scoped>
.costInfo {
  font-size: 18px;
}
.redText,
.remainInfo,
.costText {
  color: #f56c6c;
  font-weight: bold;
}

.greenText {
  font-weight: bold;
  color: #67c23a;
}

.infoItem {
  margin-bottom: 30px;
}

.costInfoTable {
  font-size: 16px;
}

.tips {
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}
</style>