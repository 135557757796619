import router from '@/src/router';
import axios from 'axios';
import { ElMessage } from "element-plus";
import { baseURL } from '../constant';
import { clearUserInfo } from '../src/utils/utils';

axios.defaults.baseURL = baseURL
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (res) => {
        const {
            data: {
                code: code,
                message: msg
            }
        } = res
        // console.log(res)
        // if (/500/.test(code)) {
        //     ElMessage({
        //         message: "登录过期，请重新登录",
        //         type: "error"
        //     })
        //     clearUserInfo()
        //     router.replace({ name: 'login' })
        //     return
        // }
        if (/[45][\d][\d]/.test(code)) {
            let isShowErrorMsg = true
            const hideErrorMsg = () => {
                isShowErrorMsg = false
            }
            setTimeout(() => {
                isShowErrorMsg && ElMessage({
                    message: msg,
                    type: "error"
                })
            })

            return Promise.reject({ data: res.data, hideErrorMsg })
        }
        if (/2[\d][\d]/.test(code) || code === 0) {
            return res;
        }

    },
    (err) => {
        const {
            response: {
                status,
                statusText
            }
        } = err
        if (status === 401) {
            ElMessage({
                message: "登录过期，请重新登录",
                type: "error"
            })
            clearUserInfo()
            router.replace({ name: 'login' })
            return
        }
        
        return Promise.reject(err)
    })

export default axios