
<script setup>
import { imgPath, imgSize, infoData } from "../../../../constant";
import { ref } from "vue";
const showInfo = (info) => {
  if (typeof info === "string") {
    return info;
  }
  if (Array.isArray(info)) {
    return info.join("\n");
  }
};
const activeNames = 1;
let isCollapse = ref(true);
const open = () => {
  isCollapse.value = !isCollapse.value;
};
</script>

<template>
  <div class="infoWrap">
    <div class="info">
      <div
        class="infoItem"
        v-for="(item, index) in infoData"
        :key="item + '-' + index"
      >
        <h2 class="title">{{ item.title }}</h2>
        <div class="content">{{ showInfo(item.content) }}</div>
        <a v-if="item.url" :href="'#' + item.url">cohmetrix说明</a>
      </div>
      <img :src="imgPath" alt="" :width="imgSize" />
    </div>
  </div>
</template>


<style scoped>
.title {
  color: #409eff;
}
.infoItem {
  margin-bottom: 30px;
  white-space: pre-line;
}
.content {
  margin-top: 20px;
}
.elMenu {
  position: relative;
}

.infoWrap {
  width: 100%;
  height: 100%;
  padding: 30px 30px 0 60px;
  box-sizing: border-box;
  position: relative;
  border-right: 1px solid #DCDCDC;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 0;
  min-height: 400px;
}
</style>